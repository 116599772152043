import logoFooter from './images/logofooter.svg'
import s from './Footer.module.css'
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Footer = () => {

    const [ipCopy, setIpCopy] = useState('Playstrix.net');
    const handleCopyToClipboard = () => {
        const serverIP = "Playstrix.net";

        navigator.clipboard.writeText(serverIP)
            .then(() => {
                setIpCopy('Скопировано!')
                setTimeout(() => {
                    setIpCopy('Playstrix.net')
                }, 2000)
            })
            .catch(err => {
                setIpCopy('Не удалось скопировать Playstrix.net!')
            });
    };
    return <>
        <div className={s.footer}>
            <div className={s.inFooter}>
                <div className={s.topFooter}>
                    <div className={s.areaTop}>
                        <div className={s.logo}>
                            <img src={logoFooter} alt="" />
                        </div>
                    </div>

                    <div className={s.areaTop}>
                        <div className={s.menu}>
                            <div className={s.menuItems}>
                                <Link to="/"><div className={s.itemMenu}>Главная</div></Link>
                                <Link to="/bans"><div className={s.itemMenu}>Баны</div></Link>
                            </div>
                            <div className={s.menuItems}>
                                <Link to="/gallery"><div className={s.itemMenu}>Галерея</div></Link>
                                <Link to="https://docs.playstrix.net/" target='__blank'><div className={s.itemMenu}>Вики</div></Link>
                                <Link to="/shop"> <div className={s.itemMenu}>Донат</div> </Link>
                            </div>
                        </div>
                    </div>
                    <div className={s.areaTop}>
                        <div className={s.blockSocial}>
                            <a href="https://discord.playstrix.net/" target='_blank'><div className={s.itemSoc}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path d="M32.1175 8.88374C29.9008 7.8504 27.5008 7.1004 25.0008 6.66707C24.9789 6.66637 24.9571 6.67048 24.937 6.67912C24.9168 6.68776 24.8988 6.70071 24.8842 6.71707C24.5842 7.26707 24.2342 7.98374 24.0008 8.53374C21.3492 8.13374 18.6525 8.13374 16.0008 8.53374C15.7675 7.96707 15.4175 7.26707 15.1008 6.71707C15.0842 6.68374 15.0342 6.66707 14.9842 6.66707C12.4842 7.1004 10.1008 7.8504 7.86751 8.88374C7.85085 8.88374 7.83418 8.9004 7.81751 8.91707C3.28418 15.7004 2.03418 22.3004 2.65085 28.8337C2.65085 28.8671 2.66751 28.9004 2.70085 28.9171C5.70085 31.1171 8.58418 32.4504 11.4342 33.3337C11.4842 33.3504 11.5342 33.3337 11.5508 33.3004C12.2175 32.3837 12.8175 31.4171 13.3342 30.4004C13.3675 30.3337 13.3342 30.2671 13.2675 30.2504C12.3175 29.8837 11.4175 29.4504 10.5342 28.9504C10.4675 28.9171 10.4675 28.8171 10.5175 28.7671C10.7008 28.6337 10.8842 28.4837 11.0675 28.3504C11.1008 28.3171 11.1508 28.3171 11.1842 28.3337C16.9175 30.9504 23.1008 30.9504 28.7675 28.3337C28.8008 28.3171 28.8508 28.3171 28.8842 28.3504C29.0675 28.5004 29.2508 28.6337 29.4342 28.7837C29.5008 28.8337 29.5008 28.9337 29.4175 28.9671C28.5508 29.4837 27.6342 29.9004 26.6842 30.2671C26.6175 30.2837 26.6008 30.3671 26.6175 30.4171C27.1508 31.4337 27.7508 32.4004 28.4008 33.3171C28.4508 33.3337 28.5008 33.3504 28.5508 33.3337C31.4175 32.4504 34.3008 31.1171 37.3008 28.9171C37.3342 28.9004 37.3508 28.8671 37.3508 28.8337C38.0842 21.2837 36.1342 14.7337 32.1842 8.91707C32.1675 8.9004 32.1508 8.88374 32.1175 8.88374ZM14.2008 24.8504C12.4842 24.8504 11.0508 23.2671 11.0508 21.3171C11.0508 19.3671 12.4508 17.7837 14.2008 17.7837C15.9675 17.7837 17.3675 19.3837 17.3508 21.3171C17.3508 23.2671 15.9508 24.8504 14.2008 24.8504ZM25.8175 24.8504C24.1008 24.8504 22.6675 23.2671 22.6675 21.3171C22.6675 19.3671 24.0675 17.7837 25.8175 17.7837C27.5842 17.7837 28.9842 19.3837 28.9675 21.3171C28.9675 23.2671 27.5842 24.8504 25.8175 24.8504Z" fill="white" fill-opacity="0.76" />
                                </svg>
                            </div>
                            </a>
                            <a href="https://www.youtube.com/@playstrix3105" target='_blank'>
                                <div className={s.itemSoc}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M20.4054 6.66699C21.2954 6.67199 23.522 6.69366 25.8887 6.78866L26.7287 6.82533C29.1104 6.93699 31.4904 7.13033 32.672 7.45866C34.247 7.90199 35.4837 9.19199 35.902 10.8287C36.5687 13.4287 36.652 18.4987 36.662 19.727L36.6637 19.9803V20.2703C36.652 21.4987 36.5687 26.5703 35.902 29.1687C35.4787 30.8103 34.2404 32.102 32.672 32.5387C31.4904 32.867 29.1104 33.0603 26.7287 33.172L25.8887 33.2103C23.522 33.3037 21.2954 33.327 20.4054 33.3303L20.0137 33.332H19.5887C17.7054 33.3203 9.8287 33.2353 7.32203 32.5387C5.7487 32.0953 4.51036 30.8053 4.09203 29.1687C3.42536 26.5687 3.34203 21.4987 3.33203 20.2703V19.727C3.34203 18.4987 3.42536 13.427 4.09203 10.8287C4.51536 9.18699 5.7537 7.89533 7.3237 7.46033C9.8287 6.76199 17.707 6.67699 19.5904 6.66699H20.4054ZM16.6637 14.167V25.8337L26.6637 20.0003L16.6637 14.167Z" fill="white" fill-opacity="0.76" />
                                    </svg>
                                </div>
                            </a>

                            <a href="https://t.me/playstrixnet" target='_blank'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.85 7.05005C32.2183 6.89505 32.6214 6.84159 33.0173 6.89524C33.4133 6.94889 33.7876 7.10768 34.1013 7.35509C34.4151 7.6025 34.6568 7.92948 34.8013 8.302C34.9458 8.67452 34.9878 9.07897 34.9229 9.47323L31.543 29.9749C31.2151 31.9524 29.0453 33.0865 27.2317 32.1015C25.7146 31.2774 23.4613 30.0076 21.4345 28.6828C20.4211 28.0196 17.3169 25.896 17.6984 24.3849C18.0263 23.0928 23.2422 18.2375 26.2227 15.3509C27.3926 14.2168 26.8591 13.5625 25.4776 14.6057C22.047 17.1958 16.539 21.1346 14.7179 22.2433C13.1114 23.221 12.2738 23.3879 11.2724 23.221C9.4453 22.9169 7.75087 22.446 6.3679 21.8723C4.4991 21.0973 4.59001 18.5281 6.36641 17.78L31.85 7.05005Z" fill="white" fill-opacity="0.76" />
                                </svg>
                            </a>


                        </div>
                        <div className={s.ipSelector} onClick={handleCopyToClipboard}>
                            {ipCopy}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M3.25 9.5C3.25 6.32436 5.82436 3.75 9 3.75H16.0129C16.4271 3.75 16.7629 4.08579 16.7629 4.5C16.7629 4.91421 16.4271 5.25 16.0129 5.25H9C6.65279 5.25 4.75 7.15279 4.75 9.5V16.6069C4.75 17.0211 4.41421 17.3569 4 17.3569C3.58579 17.3569 3.25 17.0211 3.25 16.6069V9.5Z" fill="white" />
                                <path d="M18.4026 7.29326C15.1616 6.93104 11.8384 6.93104 8.59748 7.29326C7.6742 7.39645 6.93227 8.12304 6.82344 9.05348C6.43906 12.34 6.43906 15.66 6.82344 18.9465C6.93227 19.877 7.6742 20.6035 8.59748 20.7067C11.8384 21.069 15.1616 21.069 18.4026 20.7067C19.3258 20.6035 20.0678 19.877 20.1766 18.9465C20.561 15.66 20.561 12.34 20.1766 9.05348C20.0678 8.12304 19.3258 7.39645 18.4026 7.29326Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                </div>
                <svg width="100%" height="10" viewBox="0 0 1920 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5L941 5.00008" stroke="#EA8345" stroke-width="2" stroke-linecap="round" />
                    <path d="M979 5L1920 5.00008" stroke="#EA8345" stroke-width="2" stroke-linecap="round" />
                    <circle cx="960" cy="5" r="5" fill="#EA8345" />
                </svg>
                <div className={s.areaBottom}>
                    <div className={s.areaBotFooter + ' ' + s.areaBotFooterAdd}>
                        <span className={s.boldText}>Copyright 2024</span>
                        <span className={s.sampleText}>Все права защищены. Авторские права на Minecraft принадлежат Mojang Studios и никак не связаны с PlayStrix.</span>
                    </div>
                    <div className={s.areaBotFooter}>
                        <Link to="/policy"><span className={s.sampleText}>Политика конфиденциальности</span></Link>
                    </div>
                </div>


            </div>
        </div>
    </>
}

export default Footer;
