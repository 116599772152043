import s from './Cart.module.css'
import Footer from '../Footer/Footer'
import backCart from './images/background.webp'
import backFirst from './images/bgfirst.webp'
import backsecond from './images/bgsecond.webp'
import { createRef, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';



const Cart = ({ getDataLocalStorage }) => {
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [coupons, setCoupons] = useState("");
    const [errorPay, setArrorPay] = useState('Введите ник');
    const notification = useRef('')
    const nameplayer = useRef('')
    const promocode = useRef('')

    const navigate = useNavigate()


    const handleEasyDonateBuy = async () => {

        const cart = JSON.parse(localStorage.getItem('cart') || '[]');
        const products = cart.reduce((acc, item) => {
            acc[item.id] = item.amount;
            return acc;
        }, {});


        let data = {
            customer: nameplayer.current.value,
            server_id: "104456",
            products: products,
            email: "swimafreak1997@gmail.com",
            coupon: coupons,
            success_url: "https://playstrix.net"
        };

        try {
            const response = await fetch('/api/easydonate', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (response.ok) {
                window.location.href = `${result.response.url}`; // Замените '/success-page' на нужный URL
            }
        } catch (error) {
            console.error('Error sending data to backend:', error);
            setArrorPay('Ошибка! Вы не ввели свой ник')
        }
    };




    const checkPromo = async (enterCounpon) => {
        try {
            const response = await fetch('/api/checkpromo', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                }

            });

            const result = await response.json();

            result.response.map((item) => {
                console.log(item.code, String(enterCounpon).toLocaleUpperCase())
                console.log(item)
                if (item.code == String(enterCounpon).toLocaleUpperCase() && item.limit >= 1) {
                    setCoupons(item.code);
                    if (notification.current) {
                        notification.current.innerText = 'Промокод принят! Будет учтен при оплате!';
                        notification.current.style.display = 'block';
                    }

                    setTimeout(() => {
                        if (notification.current) {
                            notification.current.style.display = 'none';
                        }
                    }, 2000);


                } else {
                    if (notification.current) {
                        notification.current.innerText = 'Промокод недействителен!';
                        notification.current.style.display = 'block';
                    }
                    setCoupons("");

                    setTimeout(() => {
                        if (notification.current) {
                            notification.current.style.display = 'none';
                        }
                    }, 2000);

                }
            })

        } catch (error) {
            console.error('Error sending data to backend:', error);

        }
    };






    const removeFromCart = (itemName) => {
        const updatedCart = cart.filter((item) => item.name !== itemName);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));

        if (notification.current) {
            notification.current.innerText = 'Удалено из корзины';
            notification.current.style.display = 'block';
        }
        getDataLocalStorage()

        setTimeout(() => {
            if (notification.current) {
                notification.current.style.display = 'none';
            }

        }, 2000);
    };

    const handleChange = (event, itemName) => {
        let newAmount = event.target.value;
        if (event.target.value <= 0) {
            newAmount = 1
        }
        console.log(newAmount)
        const updatedCart = cart.map((item) =>
            item.name === itemName ? { ...item, amount: Number(newAmount) } : item
        );
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };



    useEffect(() => {
        const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCart(savedCart);
    }, []);

    useEffect(() => {

        const calculateTotal = () => {
            const sum = cart.reduce((acc, item) => acc + item.price * item.amount, 0);
            setTotal(sum);
        };

        calculateTotal();
    }, [cart]);





    const [exchangeRate, setExchangeRate] = useState(null);

    useEffect(() => {
        // Функция для получения курса обмена один раз при загрузке
        const fetchExchangeRate = async () => {
            try {
                const response = await fetch('https://www.cbr-xml-daily.ru/latest.js');
                const data = await response.json();
                setExchangeRate(data.rates['USD']);
            } catch (error) {
                console.error('Error fetching the data:', error);
            }
        };

        fetchExchangeRate();
    }, []);


    return (
        <>
            <div className={s.MainAreaCart} style={{ background: `url(${backCart})` }}>
                <div className={s.inMainAreaCart}>
                    <div className={s.titleCart}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                            <path d="M30.6658 12.23L26.2726 1.91665C25.8272 0.870998 24.6011 0.406224 23.5744 0.893905L1.81543 11.2302C0.290842 11.9544 0.293596 14.1251 1.82002 14.8454L23.5785 25.1139C24.604 25.5978 25.8262 25.1333 26.2714 24.0904L30.6651 13.7991C30.8791 13.298 30.8793 12.7313 30.6658 12.23Z" fill="white" />
                        </svg>
                        <span>Ваша корзина</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                            <path d="M0.334242 13.77L4.72737 24.0834C5.17278 25.129 6.39894 25.5938 7.42556 25.1061L29.1846 14.7698C30.7092 14.0456 30.7064 11.8749 29.18 11.1546L7.42151 0.886145C6.39605 0.402199 5.17378 0.866703 4.72855 1.90956L0.334882 12.2009C0.120948 12.702 0.120716 13.2687 0.334242 13.77Z" fill="white" />
                        </svg>
                    </div>

                    <div className={s.areaListItemsCart}>
                        {cart.length === 0 ? (
                            <div className={s.nonCart}>
                                <span>Ваша корзина пуста</span>
                            </div>
                        ) : (
                            <div className={s.inAreaItemsCart}>

                                <div className={s.leftPartAreaCart}>
                                    {cart.map((item) => (

                                        <div className={s.itemDonate} key={item.name}>

                                            <div className={s.inItemDonate}>
                                                <div className={s.mainAreaItemDonate}>
                                                    <div className={s.imageDonateItem}>
                                                        <img src={item.img} alt={item.name} />
                                                    </div>
                                                    <div className={s.infoDonateItem}>
                                                        <div className={s.nameDonateItem}>{item.name}</div>
                                                        <div
                                                            className={s.descDonateItem}
                                                            dangerouslySetInnerHTML={{ __html: item.desc }}
                                                        ></div>
                                                        <input
                                                            type="number"
                                                            placeholder="кол-во"
                                                            value={item.amount}
                                                            onChange={(e) => handleChange(e, item.name)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={s.dataDonateResult}>
                                                    <div className={s.mainPriceDonate}>
                                                        {Math.ceil(item.price * item.amount).toFixed(0)}₽
                                                    </div>
                                                    <div className={s.mainPriceDonate + ' ' + s.smallPrice}>
                                                        {Math.ceil(item.price * item.amount * exchangeRate).toFixed(0)}$
                                                    </div>
                                                    <div
                                                        className={s.btnRemoveItem}
                                                        onClick={() => removeFromCart(item.name)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <span>Удалить</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>


                                <div className={s.rightPartCart}>
                                    <div className={s.inRightPartCart}>
                                        <div className={s.areaResultCart} style={{ background: `url(${backFirst})` }}>
                                            <div className={s.inResultCart}>
                                                <div className={s.titleResultCart}>
                                                    <div className={s.textResultCart}>Итог:</div>
                                                    <div className={s.areaMoneyRes}>
                                                        <div className={s.priceMainCart}>{(total).toFixed(0)}₽</div>
                                                        <div className={s.priceMainCart + ' ' + s.smallPrice}>{(total * exchangeRate).toFixed(0)}$</div>
                                                        <div className={s.promoCodeText}>{coupons != "" ? "Ваш промокод: " + coupons : ""}</div>
                                                    </div>

                                                </div>

                                                <div action="" className={s.areaInputsCart}>
                                                    <input type="text" placeholder={errorPay} ref={nameplayer} required />
                                                    <button className={s.paybtn} onClick={() => { handleEasyDonateBuy() }}>Приобрести</button>
                                                </div>

                                                <div className={s.simpleCartPolicy}>
                                                    Нажимая кнопку приобрести, вы соглашаетесь с{' '}
                                                    <Link to="/policy"><span className={s.colored}>пользовательским соглашением.</span></Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={s.blockPromocode} style={{ background: `url(${backsecond})` }}>
                                            <div className={s.inPromoCode}>
                                                <div className={s.titlePromo}>Промокод:</div>
                                                <input type="text" placeholder="Введите промокод" ref={promocode} />
                                                <button className={s.acceptPromo} onClick={() => { checkPromo(promocode.current.value) }}>Применить</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            </div>

            <div className={s.notification} ref={notification}>

            </div>

        
        </>
    );
};

export default Cart;
