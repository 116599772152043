import { useEffect, useRef, useState } from 'react'
import s from './Header.module.css'
import logo from './assets/img/Logo.svg'
import { Link } from 'react-router-dom'
const Header = ({ amountCart }) => {
    const [openMenu, setOpenMenu] = useState(false)
    const [selectLang, setSelectLang] = useState('Ru')
    const [ipCopy, setIpCopy] = useState('Playstrix.net');
    const [shadowHeader, setShadowHeader] = useState('')
    const [countPlayers, setCountPlayers] = useState(0)


    const mobileMenu = useRef();

    const handleOpenMobileMenu = () => {
        if (mobileMenu.current) {
            setOpenMenu(prev => !prev)
        }
    }


    const getPlayerOnline = async () => {
        try {
            let response = await fetch('/api/dataserver');
            let responseData = await response.json();
            setCountPlayers(responseData.players.online);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };






    const handleCopyToClipboard = () => {
        const serverIP = "Playstrix.net";

        navigator.clipboard.writeText(serverIP)
            .then(() => {
                setIpCopy('Скопировано!')
                setTimeout(() => {
                    setIpCopy('Playstrix.net')
                }, 2000)
            })
            .catch(err => {
                setIpCopy('Не удалось скопировать Playstrix.net!')
            });
    };


    useEffect(() => {
        const handleScrollPOs = () => {
            setShadowHeader(window.pageYOffset);

        };


        window.addEventListener('scroll', handleScrollPOs);


        return () => {
            window.removeEventListener('scroll', handleScrollPOs);
        };
    }, []);

    useEffect(() => {
        getPlayerOnline()
    }, [])





    return (<>
        <div className={s.header + ' ' + `${shadowHeader > 100 ? s.shadowHeader : ''}`}>
            <div className={s.inHeader}>


                <div className={s.header__block}>
                <Link to="/"><div className={s.logo}><img src={logo} alt="" /></div></Link>
                </div>

                <div className={s.header__block}>
                    <div className={s.menu}>

                        <Link to="/"><div className={s.itemMenu}>Главная</div></Link>

                        <Link to="/bans"><div className={s.itemMenu}>Баны</div></Link>
                        <Link to="/gallery"><div className={s.itemMenu}>Галерея</div></Link>
                        <Link to="https://docs.playstrix.net/" target='__blank'><div className={s.itemMenu}>Вики</div></Link>
                        <Link to="/shop"> <div className={s.itemMenu}>Донат</div> </Link>

                    </div>
                </div>

                <div className={s.header__block}>
                    <div className={s.ipLocalization}>
                        <div className={s.onlineModule}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M8 8.5C6.9 8.5 5.95833 8.10833 5.175 7.325C4.39167 6.54167 4 5.6 4 4.5C4 3.4 4.39167 2.45833 5.175 1.675C5.95833 0.891667 6.9 0.5 8 0.5C9.1 0.5 10.0417 0.891667 10.825 1.675C11.6083 2.45833 12 3.4 12 4.5C12 5.6 11.6083 6.54167 10.825 7.325C10.0417 8.10833 9.1 8.5 8 8.5ZM0 16.5V13.7C0 13.1333 0.145833 12.6125 0.4375 12.1375C0.729167 11.6625 1.11667 11.3 1.6 11.05C2.63333 10.5333 3.68333 10.1458 4.75 9.8875C5.81667 9.62917 6.9 9.5 8 9.5C9.1 9.5 10.1833 9.62917 11.25 9.8875C12.3167 10.1458 13.3667 10.5333 14.4 11.05C14.8833 11.3 15.2708 11.6625 15.5625 12.1375C15.8542 12.6125 16 13.1333 16 13.7V16.5H0ZM2 14.5H14V13.7C14 13.5167 13.9542 13.35 13.8625 13.2C13.7708 13.05 13.65 12.9333 13.5 12.85C12.6 12.4 11.6917 12.0625 10.775 11.8375C9.85833 11.6125 8.93333 11.5 8 11.5C7.06667 11.5 6.14167 11.6125 5.225 11.8375C4.30833 12.0625 3.4 12.4 2.5 12.85C2.35 12.9333 2.22917 13.05 2.1375 13.2C2.04583 13.35 2 13.5167 2 13.7V14.5ZM8 6.5C8.55 6.5 9.02083 6.30417 9.4125 5.9125C9.80417 5.52083 10 5.05 10 4.5C10 3.95 9.80417 3.47917 9.4125 3.0875C9.02083 2.69583 8.55 2.5 8 2.5C7.45 2.5 6.97917 2.69583 6.5875 3.0875C6.19583 3.47917 6 3.95 6 4.5C6 5.05 6.19583 5.52083 6.5875 5.9125C6.97917 6.30417 7.45 6.5 8 6.5Z" fill="white" />
                            </svg>
                            <span className={s.ipcopy}>{countPlayers}</span>
                        </div>
                        <div className={s.ipSelector}>
                            <span className={s.ipcopy} onClick={handleCopyToClipboard}>{ipCopy}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M3.25 9.5C3.25 6.32436 5.82436 3.75 9 3.75H16.0129C16.4271 3.75 16.7629 4.08579 16.7629 4.5C16.7629 4.91421 16.4271 5.25 16.0129 5.25H9C6.65279 5.25 4.75 7.15279 4.75 9.5V16.6069C4.75 17.0211 4.41421 17.3569 4 17.3569C3.58579 17.3569 3.25 17.0211 3.25 16.6069V9.5Z" fill="white" />
                                <path d="M18.4026 7.29326C15.1616 6.93104 11.8384 6.93104 8.59748 7.29326C7.6742 7.39645 6.93227 8.12304 6.82344 9.05348C6.43906 12.34 6.43906 15.66 6.82344 18.9465C6.93227 19.877 7.6742 20.6035 8.59748 20.7067C11.8384 21.069 15.1616 21.069 18.4026 20.7067C19.3258 20.6035 20.0678 19.877 20.1766 18.9465C20.561 15.66 20.561 12.34 20.1766 9.05348C20.0678 8.12304 19.3258 7.39645 18.4026 7.29326Z" fill="white" />
                            </svg>
                        </div>
                        <Link to="/cart">
                            <div className={s.areaCartIcon}>
                                <div className={s.cartIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M16.0822 7.29122C16.4274 6.69336 16.2225 5.92887 15.6247 5.58369C15.0268 5.23851 14.2623 5.44336 13.9171 6.04122L11.1507 10.8329H9.16634C7.32539 10.8329 5.83301 12.3253 5.83301 14.1662C5.83301 15.3595 6.46007 16.4064 7.40265 16.9953C7.49864 17.0552 7.61073 17.0829 7.72391 17.0829H32.2754C32.3886 17.0829 32.5007 17.0552 32.5967 16.9953C33.5393 16.4064 34.1663 15.3595 34.1663 14.1662C34.1663 12.3253 32.674 10.8329 30.833 10.8329H28.8487L26.0822 6.04122C25.737 5.44336 24.9725 5.23851 24.3747 5.58369C23.7768 5.92887 23.572 6.69336 23.9171 7.29122L25.9619 10.8329H14.0374L16.0822 7.29122Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.2243 19.9739C32.2601 19.7698 32.1032 19.5829 31.896 19.5829H8.10336C7.89621 19.5829 7.73923 19.7698 7.77505 19.9739L9.44257 29.4714C9.78947 31.4472 11.3712 32.9737 13.3581 33.2501C17.7646 33.8633 22.2348 33.8633 26.6413 33.2501C28.6282 32.9737 30.2099 31.4472 30.5568 29.4714L32.2243 19.9739ZM17.9163 23.3329C17.9163 22.6425 17.3567 22.0829 16.6663 22.0829C15.976 22.0829 15.4163 22.6425 15.4163 23.3329V26.6662C15.4163 27.3566 15.976 27.9162 16.6663 27.9162C17.3567 27.9162 17.9163 27.3566 17.9163 26.6662V23.3329ZM23.333 22.0829C24.0234 22.0829 24.583 22.6425 24.583 23.3329V26.6662C24.583 27.3566 24.0234 27.9162 23.333 27.9162C22.6427 27.9162 22.083 27.3566 22.083 26.6662V23.3329C22.083 22.6425 22.6427 22.0829 23.333 22.0829Z" fill="white" />
                                    </svg>
                                </div>
                                <div className={s.indicatorAmount}>
                                    {amountCart}
                                </div>
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </div>

        <div className={s.mobileHeader}>
            <div className={s.inMobileHeader}>
                <div className={s.header__block}>
                    <Link to="/"> <div className={s.logo}><img src={logo} alt="" /></div></Link>
                </div>

                <div className={s.header__block}>
                    <div className={s.ipLocalization}>
                        <Link to="/cart">
                            <div className={s.areaCartIcon}>
                                <div className={s.cartIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M16.0822 7.29122C16.4274 6.69336 16.2225 5.92887 15.6247 5.58369C15.0268 5.23851 14.2623 5.44336 13.9171 6.04122L11.1507 10.8329H9.16634C7.32539 10.8329 5.83301 12.3253 5.83301 14.1662C5.83301 15.3595 6.46007 16.4064 7.40265 16.9953C7.49864 17.0552 7.61073 17.0829 7.72391 17.0829H32.2754C32.3886 17.0829 32.5007 17.0552 32.5967 16.9953C33.5393 16.4064 34.1663 15.3595 34.1663 14.1662C34.1663 12.3253 32.674 10.8329 30.833 10.8329H28.8487L26.0822 6.04122C25.737 5.44336 24.9725 5.23851 24.3747 5.58369C23.7768 5.92887 23.572 6.69336 23.9171 7.29122L25.9619 10.8329H14.0374L16.0822 7.29122Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.2243 19.9739C32.2601 19.7698 32.1032 19.5829 31.896 19.5829H8.10336C7.89621 19.5829 7.73923 19.7698 7.77505 19.9739L9.44257 29.4714C9.78947 31.4472 11.3712 32.9737 13.3581 33.2501C17.7646 33.8633 22.2348 33.8633 26.6413 33.2501C28.6282 32.9737 30.2099 31.4472 30.5568 29.4714L32.2243 19.9739ZM17.9163 23.3329C17.9163 22.6425 17.3567 22.0829 16.6663 22.0829C15.976 22.0829 15.4163 22.6425 15.4163 23.3329V26.6662C15.4163 27.3566 15.976 27.9162 16.6663 27.9162C17.3567 27.9162 17.9163 27.3566 17.9163 26.6662V23.3329ZM23.333 22.0829C24.0234 22.0829 24.583 22.6425 24.583 23.3329V26.6662C24.583 27.3566 24.0234 27.9162 23.333 27.9162C22.6427 27.9162 22.083 27.3566 22.083 26.6662V23.3329C22.083 22.6425 22.6427 22.0829 23.333 22.0829Z" fill="white" />
                                    </svg>
                                </div>
                                <div className={s.indicatorAmount}>
                                    {amountCart}
                                </div>
                            </div>
                        </Link>

                        <div className={s.burgerMenu} onClick={() => { handleOpenMobileMenu() }}>
                            {openMenu ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            ) :
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H19C19.4142 12.75 19.75 12.4142 19.75 12Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 7C19.75 6.58579 19.4142 6.25 19 6.25H5C4.58579 6.25 4.25 6.58579 4.25 7C4.25 7.41421 4.58579 7.75 5 7.75H19C19.4142 7.75 19.75 7.41421 19.75 7Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 17C19.75 16.5858 19.4142 16.25 19 16.25H5C4.58579 16.25 4.25 16.5858 4.25 17C4.25 17.4142 4.58579 17.75 5 17.75H19C19.4142 17.75 19.75 17.4142 19.75 17Z" fill="white" />
                                    </svg>
                                )
                            }

                        </div>


                    </div>
                </div>


                <div className={s.mobileMenu} ref={mobileMenu} >
                    <div className={s.menu} style={{ display: openMenu ? 'flex' : 'none' }}>
                        <Link to="/"><div className={s.itemMenu} onClick={() => { handleOpenMobileMenu() }}>Главная</div></Link>
                        <Link to="/bans"><div className={s.itemMenu} onClick={() => { handleOpenMobileMenu() }}>Баны</div></Link>
                        <Link to="/gallery"><div className={s.itemMenu} onClick={() => { handleOpenMobileMenu() }}>Галерея</div></Link>
                        <Link to="https://docs.playstrix.net/" target='__blank'><div className={s.itemMenu}>Вики</div></Link>
                        <Link to="/shop"> <div className={s.itemMenu} onClick={() => { handleOpenMobileMenu() }}>Донат</div> </Link>

                        <div className={s.onlineModule}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M8 8.5C6.9 8.5 5.95833 8.10833 5.175 7.325C4.39167 6.54167 4 5.6 4 4.5C4 3.4 4.39167 2.45833 5.175 1.675C5.95833 0.891667 6.9 0.5 8 0.5C9.1 0.5 10.0417 0.891667 10.825 1.675C11.6083 2.45833 12 3.4 12 4.5C12 5.6 11.6083 6.54167 10.825 7.325C10.0417 8.10833 9.1 8.5 8 8.5ZM0 16.5V13.7C0 13.1333 0.145833 12.6125 0.4375 12.1375C0.729167 11.6625 1.11667 11.3 1.6 11.05C2.63333 10.5333 3.68333 10.1458 4.75 9.8875C5.81667 9.62917 6.9 9.5 8 9.5C9.1 9.5 10.1833 9.62917 11.25 9.8875C12.3167 10.1458 13.3667 10.5333 14.4 11.05C14.8833 11.3 15.2708 11.6625 15.5625 12.1375C15.8542 12.6125 16 13.1333 16 13.7V16.5H0ZM2 14.5H14V13.7C14 13.5167 13.9542 13.35 13.8625 13.2C13.7708 13.05 13.65 12.9333 13.5 12.85C12.6 12.4 11.6917 12.0625 10.775 11.8375C9.85833 11.6125 8.93333 11.5 8 11.5C7.06667 11.5 6.14167 11.6125 5.225 11.8375C4.30833 12.0625 3.4 12.4 2.5 12.85C2.35 12.9333 2.22917 13.05 2.1375 13.2C2.04583 13.35 2 13.5167 2 13.7V14.5ZM8 6.5C8.55 6.5 9.02083 6.30417 9.4125 5.9125C9.80417 5.52083 10 5.05 10 4.5C10 3.95 9.80417 3.47917 9.4125 3.0875C9.02083 2.69583 8.55 2.5 8 2.5C7.45 2.5 6.97917 2.69583 6.5875 3.0875C6.19583 3.47917 6 3.95 6 4.5C6 5.05 6.19583 5.52083 6.5875 5.9125C6.97917 6.30417 7.45 6.5 8 6.5Z" fill="white" />
                            </svg>
                            <span className={s.ipcopy}>{countPlayers}</span>
                        </div>

                        <div className={s.ipSelector}>
                            <span className={s.ipcopy} onClick={handleCopyToClipboard}>{ipCopy}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M3.25 9.5C3.25 6.32436 5.82436 3.75 9 3.75H16.0129C16.4271 3.75 16.7629 4.08579 16.7629 4.5C16.7629 4.91421 16.4271 5.25 16.0129 5.25H9C6.65279 5.25 4.75 7.15279 4.75 9.5V16.6069C4.75 17.0211 4.41421 17.3569 4 17.3569C3.58579 17.3569 3.25 17.0211 3.25 16.6069V9.5Z" fill="white" />
                                <path d="M18.4026 7.29326C15.1616 6.93104 11.8384 6.93104 8.59748 7.29326C7.6742 7.39645 6.93227 8.12304 6.82344 9.05348C6.43906 12.34 6.43906 15.66 6.82344 18.9465C6.93227 19.877 7.6742 20.6035 8.59748 20.7067C11.8384 21.069 15.1616 21.069 18.4026 20.7067C19.3258 20.6035 20.0678 19.877 20.1766 18.9465C20.561 15.66 20.561 12.34 20.1766 9.05348C20.0678 8.12304 19.3258 7.39645 18.4026 7.29326Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
    )
}

export default Header