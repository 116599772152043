
import s from './Main.module.css'
import layer1 from './images/rocks-bg.webp'
import layer2 from './images/forest-bg.webp'
import layer3 from './images/owls-layer.webp'
import layer4 from './images/hills-layer.webp'
import layer5 from './images/sky-layer.webp'
import layerPlaystrix from './images/playstrixLayer.webp'
import heroscreen from './images/heroScreen.webp'
import leavesTop from './images/leaves_top.svg'
import leavesbottom from './images/leaves_bottom.webp'
import backMountainsTypeServer from './images/backMountainsTypeServer.webp'
import survivalimage from './images/survivalimage.webp'

import community from './images/community.webp'
import events from './images/events.webp'
import stable from './images/stable.webp'
import livesmask from './images/livesmask.svg'

import bgfooter from './images/bgfooter.webp'
import rocksBottom from './images/rocks-bottom.webp'
import highGrassBottom from './images/highGrass-bottom.webp'


import seasonIMG from './images/seas.webp'
import obIMG from './images/ob_image.webp'
import svIMG from './images/sv.webp'


import ownerFront from './images/bgcard1.webp'
import OwnerBack from './images/bgcardback.webp'

import moderFront from './images/moder_front.webp'
import moderback from './images/moder_back.webp'

import teamFront from './images/team_front.webp'
import teamback from './images/team_back.webp'

import adminFront from './images/admin_front.webp'
import adminback from './images/admin_back.webp'

import helperFront from './images/helper_front.webp'
import helperback from './images/helper_back.webp'

import { useEffect, useRef, useState } from 'react'


import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer'






const ParallaxComponent = () => {
    const [scrollY, setScrollY] = useState(0);





    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getParallaxStyle = (speed) => ({
        transform: `translateY(${scrollY * speed}px)`,
        transition: '1s'
    });
    const getParallaxStyleNone = (speed) => ({
        transform: `translateY(${speed}px)`,
    });

    return (
        <div className={s.parallaxContainer}>
            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={layer5} alt="Layer 1" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyle(0.55)}>
                <img src={layerPlaystrix} alt="Layer 2.5" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyle(0.18)}>
                <img src={layer4} alt="Layer 2" className={s.parallaxImage} />
            </div>

            <div className={s.parallaxLayer} style={getParallaxStyle(0.12)}>
                <img src={layer3} alt="Layer 3" className={s.parallaxImage} />
            </div>

            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={layer2} alt="Layer 4" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={layer1} alt="Layer 5" className={s.parallaxImage} />
            </div>

        </div>
    );
};



const ScrollComponent = () => {
    const [activeImage, setActiveImage] = useState(community);
    const textBlocksRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = entry.target.getAttribute('data-index');
                    switch (index) {
                        case '0':
                            setActiveImage(community);
                            break;
                        case '1':
                            setActiveImage(events);
                            break;
                        case '2':
                            setActiveImage(stable);
                            break;
                        default:
                            break;
                    }
                }
            });
        }, { threshold: 0.5 });
        if (textBlocksRef.current && textBlocksRef.current.length > 0) {
            textBlocksRef.current.forEach(block => {
                if (block) {
                    observer.observe(block);
                }
            });
        }

        return () => {
            // Отписываемся от observer'а перед размонтированием
            if (textBlocksRef.current && textBlocksRef.current.length > 0) {
                textBlocksRef.current.forEach(block => {
                    if (block) {
                        observer.unobserve(block);
                    }
                });
            }
        };
    }, []);

    return (
        <div className={s.scrollBlock}>
            <div className={s.inScrollBlock}>
                <div className={s.titleInfoScroll}>PlayStrix - это:</div>
                <div className={s.scrollArea}>
                    <div className={s.pinSpacer}>
                        <div className={s.imagesWrap}>
                            <picture>
                                <source type="image/webp" srcSet={activeImage} />
                                <img src={activeImage} alt="Active content" />
                            </picture>
                        </div>
                    </div>

                    <div className={s.textWrap}>
                        <div
                            className={s.textBlock}
                            data-index="0"
                            ref={(el) => (textBlocksRef.current[0] = el)}
                        >
                            <h2 className={s.textH2}>Сообщество</h2>
                            <p className={s.descText}>
                                PlayStrix - это сообщество дружелюбных и адекватных игроков любого возраста! У нас вас ждет игра без токсичности и оскорблений. Вам всегда помогут и подскажут, а также вы с легкостью найдете здесь себе друзей, ведь мы - одна большая семья!
                            </p>
                        </div>

                        <div
                            className={s.textBlock}
                            data-index="1"
                            ref={(el) => (textBlocksRef.current[1] = el)}
                        >
                            <h2 className={s.textH2}>Ивенты</h2>
                            <p className={s.descText}>
                                Каждый день вас ждут уникальные ивенты! Целая команда трудится над тем, чтобы мероприятия были разнообразными и максимально интересными. Так что у нас не соскучишься от обычной игры в Майнкрафт.
                            </p>
                        </div>

                        <div
                            className={s.textBlock}
                            data-index="2"
                            ref={(el) => (textBlocksRef.current[2] = el)}
                        >
                            <h2 className={s.textH2}>Стабильность</h2>
                            <p className={s.descText}>
                                Если вы искали сервер, где вы можете играть без технических проблем - PlayStrix для вас! Наши админы стараются сделать все, чтобы сделать вашу игру комфортной, а сервер оптимизированным. Также у нас вас ждут регулярные обновления - если и стабильность, то только с развитием вперед!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};






const Main = () => {


    let [currentServer, setCurrentServer] = useState(0)
    const [teamCommand, setTeamCommand] = useState([]);




    useEffect(() => {
        const fetchTeamCommand = async () => {
            try {
                const response = await fetch('/api/allteam', {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                const result = await response.json();

                setTeamCommand(result);

            } catch (error) {
                console.error('Error fetching data from backend:', error);
            }
        };

        fetchTeamCommand();
    }, []);


    const handleServerClick = (curServer) => {

        setCurrentServer(curServer)
    };

    const cardsCommandRef = useRef(null)
    const scroll = (scrollOffset) => {
        cardsCommandRef.current.scrollLeft += scrollOffset;
    };




    <div className={s.scrollBlock}>


        <div className={s.inScrollBlock}>
            <div className={s.titleInfoScroll}>PlayStrix - это: </div>
            <div className={s.scrollArea}>
                <div className={s.pinSpacer}>
                    <div className={s.imagesWrap}>
                        <picture>
                            <source type="image/webp" srcset={community} />
                            <img src="undefined" alt="" />
                        </picture>
                        <picture>
                            <source type="image/webp" srcset={events} />
                            <img src="undefined" alt="" />
                        </picture>
                        <picture>
                            <source type="image/webp" srcset={stable} />
                            <img src="undefined" alt="" />
                        </picture>
                    </div>

                </div>

                <div className={s.textWrap}>
                    <div class={s.textBlock}>
                        <h2 class={s.textH2}>Сообщество</h2>
                        <p class={s.descText}>
                            PlayStrix - это сообщество дружелюбных и адекватных игроков любого возраста! У нас вас ждет игра без токсичности и оскорблений. Вам всегда помогут и подскажут, а также вы с легкостью найдете здесь себе друзей, ведь мы - одна большая семья!
                        </p>
                    </div>

                    <div class={s.textBlock}>
                        <h2 class={s.textH2}>Ивенты</h2>
                        <p class={s.descText}>
                            Каждый день вас ждут уникальные ивенты! Целая команда трудится над тем, чтобы мероприятия были разнообразными и максимально интересными. Так что у нас не соскучишься от обычной игры в Майнкрафт.
                        </p>
                    </div>

                    <div class={s.textBlock}>
                        <h2 class={s.textH2}>Стабильность</h2>
                        <p class={s.descText}>
                            Если вы искали сервер, где вы можете играть без технических проблем - PlayStrix для вас! Наши админы стараются сделать все, чтобы сделать вашу игру комфортной, а сервер оптимизированным. Также у нас вас ждут регулярные обновления - если и стабильность, то только с развитием вперед!
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>


    return (
        <>
            <div className={s.Main}>
                <ParallaxComponent />
            </div>

            <div className={s.aboutServerInfo}>
                <div className={s.inBlockServerInfo}>
                    <div className={s.blockTitle}>
                        <div className={s.leftInfo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                                <path d="M30.6658 12.23L26.2726 1.91665C25.8272 0.870998 24.6011 0.406224 23.5744 0.893905L1.81543 11.2302C0.290842 11.9544 0.293596 14.1251 1.82002 14.8454L23.5785 25.1139C24.604 25.5978 25.8262 25.1333 26.2714 24.0904L30.6651 13.7991C30.8791 13.298 30.8793 12.7313 30.6658 12.23Z" fill="white" />
                            </svg>
                        </div>
                        <div className={s.titleInfo}>О сервере</div>
                        <div className={s.rightInfo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                                <path d="M0.334242 13.77L4.72737 24.0834C5.17278 25.129 6.39894 25.5938 7.42556 25.1061L29.1846 14.7698C30.7092 14.0456 30.7064 11.8749 29.18 11.1546L7.42151 0.886145C6.39605 0.402199 5.17378 0.866703 4.72855 1.90956L0.334882 12.2009C0.120948 12.702 0.120716 13.2687 0.334242 13.77Z" fill="white" />
                            </svg>
                        </div>
                    </div>

                    <div className={s.textArea}>
                        Добро пожаловать на наш уютный сервер Minecraft - PlayStrix! Получите аутентичный опыт выживания без гриферства, навязчивого доната, который разрушает игровой процесс, и если вы цените дружелюбную и расслабленную атмосферу, где люди взаимодействуют друг с другом, то этот сервер создан специально для вас.
                    </div>
                </div>
            </div>

            <div className={s.serverstypes} style={{ background: `url(${backMountainsTypeServer})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <div className={s.topLeaves}><img src={leavesTop} alt="" /></div>
                <div className={s.inServersType}>
                    <div className={s.serverBlockInfo}>
                        <div className={s.preTextServer + ' ' + `${currentServer !== 0 ? s.showLineServer : ''}`} onClick={() => handleServerClick(0)}><span>Seasons</span></div>
                        <div className={s.blockMainDataServer + ' ' + `${currentServer === 0 ? s.showServerAnim : ''}`}>
                            <div className={s.imageServer}><img src={seasonIMG} loading="lazy" alt="" /></div>
                            <div className={s.blockAboutServer}>
                                <div className={s.titleServer}>Seasons</div>
                                <div className={s.descServer}>
                                    <p>Seasons — режим для тех, кто ищет уникальный игровой опыт.</p>
                                    <p> Здесь вас ждут усовершенствованные зачарования, кастомная генерация, артефакты, рыбалка, гильдии, моб-арены, смена времён года, а также варпы и магазины. Уникальные механики реагируют на действия игрока, делая каждую игру неповторимой.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.serverBlockInfo}>
                        <div className={s.preTextServer + ' ' + `${currentServer !== 1 ? s.showLineServer : ''}`} onClick={() => handleServerClick(1)}><span>Survival</span></div>
                        <div className={s.blockMainDataServer + ' ' + `${currentServer === 1 ? s.showServerAnim : ''}`}>
                            <div className={s.imageServer}><img src={svIMG} loading="lazy" alt="" /></div>
                            <div className={s.blockAboutServer}>
                                <div className={s.titleServer}>Survival</div>
                                <div className={s.descServer}>
                                    <p>Сервер, заложивший основу проекта и сохраняющий свою уникальность до сих пор.</p>
                                    <p>Survival предлагает игроку окунуться в ванильный Minecraft, где всё максимально приближено к классике. Здесь нет «оружия бога» или других излишеств — только лёгкие дополнения, которые не нарушают баланс игры.</p>
                                    <p> Традиционные плагины поддерживают торговлю, таблицы лидеров и систему наград за активность, делая процесс комфортным и увлекательным.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.serverBlockInfo}>
                        <div className={s.preTextServer + ' ' + `${currentServer !== 2 ? s.showLineServer : ''}`} onClick={() => handleServerClick(2)}><span>OneBlock</span></div>
                        <div className={s.blockMainDataServer + ' ' + `${currentServer === 2 ? s.showServerAnim : ''}`}>
                            <div className={s.imageServer}><img src={obIMG} loading="lazy" alt="" /></div>
                            <div className={s.blockAboutServer}>
                                <div className={s.titleServer}>OneBlock</div>
                                <div className={s.descServer}>
                                    <p>
                                        OneBlock — режим, наполненный захватывающими механиками!
                                    </p>
                                    <p>Начните с одного блока, добывайте ресурсы, стройте мир, и одновременно бросайте вызов боссам. Создавайте кастомные сеты брони, собирайте артефакты и усиливайте персонажа, чтобы добиваться всё новых вершин.
                                    </p>
                                    <p> Когда захочется отдохнуть от сражений, займитесь рыбалкой! Прокачивайте удочки, получайте награды за улов и открывайте новые возможности. Этот режим совмещает вызов, развитие и расслабляющий геймплей.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.bottomLeaves}><img src={leavesbottom} alt="" /></div>
            </div>

            <ScrollComponent />


            <div className={s.ourCommand}>
                <div className={s.inOurCommand}>
                    <div className={s.titleOurCommand}>Наша команда</div>
                    <div className={s.cardsCommand} ref={cardsCommandRef}>
                        <div className={s.incardCommands}>

                            {teamCommand.map((item) => {

                                let frontImage = ownerFront;
                                let backimage = OwnerBack;

                                if (item.status == 'Admin') {
                                    frontImage = adminFront;
                                    backimage = adminback;
                                } else if (item.status == 'Moderator') {
                                    frontImage = moderFront;
                                    backimage = moderback;
                                } else if (item.status == 'Team' || item.status == 'Dev') {
                                    frontImage = teamFront;
                                    backimage = teamback;
                                } else if (item.status == 'Helper') {
                                    frontImage = helperFront;
                                    backimage = helperback;
                                }
                                return (
                                    <div className={s.itemCardCommand}>
                                        <div className={s.frontface}>
                                            <div className={s.backImageFront}><img src={frontImage} alt="" /></div>
                                            <div className={s.nameCard}>{item.name}</div>
                                            <div className={s.imagecard}>
                                                <img src={'/assets/img/team/' + item.img} alt="" loading="lazy" />
                                            </div>
                                        </div>
                                        <div className={s.backface}>
                                            <div className={s.backImageBack}><img src={backimage} alt="" /></div>
                                            <div className={s.titleBackCard}>{item.status}</div>
                                            <div className={s.nicknameBackCard}>{item.name}</div>
                                            <div className={s.descBackCard}>
                                                {item.descuser}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}




                        </div>
                    </div>

                    <div className={s.btnsCommand}>
                        <div className={s.preBtn} onClick={() => { scroll(-310) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 20.0004C30 20.3319 29.8683 20.6498 29.6339 20.8843C29.3995 21.1187 29.0815 21.2504 28.75 21.2504H14.2675L19.635 26.6154C19.8697 26.8501 20.0016 27.1684 20.0016 27.5004C20.0016 27.8323 19.8697 28.1507 19.635 28.3854C19.4003 28.6201 19.0819 28.752 18.75 28.752C18.4181 28.752 18.0997 28.6201 17.865 28.3854L10.365 20.8854C10.2486 20.7693 10.1562 20.6313 10.0932 20.4795C10.0302 20.3276 9.99776 20.1648 9.99776 20.0004C9.99776 19.836 10.0302 19.6732 10.0932 19.5213C10.1562 19.3694 10.2486 19.2315 10.365 19.1154L17.865 11.6154C18.0997 11.3807 18.4181 11.2488 18.75 11.2488C19.0819 11.2488 19.4003 11.3807 19.635 11.6154C19.8697 11.8501 20.0016 12.1684 20.0016 12.5004C20.0016 12.8323 19.8697 13.1507 19.635 13.3854L14.2675 18.7504H28.75C29.0815 18.7504 29.3995 18.8821 29.6339 19.1165C29.8683 19.3509 30 19.6689 30 20.0004Z" fill="white" />
                            </svg>
                        </div>
                        <div className={s.nextBtn} onClick={() => { scroll(310) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19.9996C10 19.6681 10.1317 19.3502 10.3661 19.1157C10.6005 18.8813 10.9185 18.7496 11.25 18.7496H25.7325L20.365 13.3846C20.1303 13.1499 19.9984 12.8316 19.9984 12.4996C19.9984 12.1677 20.1303 11.8493 20.365 11.6146C20.5997 11.3799 20.9181 11.248 21.25 11.248C21.5819 11.248 21.9003 11.3799 22.135 11.6146L29.635 19.1146C29.7514 19.2307 29.8438 19.3687 29.9068 19.5205C29.9698 19.6724 30.0022 19.8352 30.0022 19.9996C30.0022 20.164 29.9698 20.3268 29.9068 20.4787C29.8438 20.6306 29.7514 20.7685 29.635 20.8846L22.135 28.3846C21.9003 28.6193 21.5819 28.7512 21.25 28.7512C20.9181 28.7512 20.5997 28.6193 20.365 28.3846C20.1303 28.1499 19.9984 27.8316 19.9984 27.4996C19.9984 27.1677 20.1303 26.8493 20.365 26.6146L25.7325 21.2496H11.25C10.9185 21.2496 10.6005 21.1179 10.3661 20.8835C10.1317 20.6491 10 20.3311 10 19.9996Z" fill="white" />
                            </svg>
                        </div>
                    </div>

                </div>
            </div>



            <div className={s.connectToPlaystrix}>
                <div className={s.inConnectToPLaystrix}>
                    <div className={s.topImageConnect}><img src={leavesTop} alt="" /></div>
                    <div className={s.mainAreaConnect}>
                        <div className={s.mainFooterImageBack}><img src={bgfooter} alt="" /></div>
                        <div className={s.textConnect}>Присоединяйтесь к сообществу PlayStrix!</div>
                        <div className={s.areaSocIcons}>
                            <Link to="https://discord.playstrix.net/"><div className={s.socialIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                    <path d="M40.1366 11.1985C44.0056 11.8886 47.7188 13.058 51.1523 14.6562C57.3377 23.8101 60.3936 34.0947 59.275 45.9427C54.6201 49.3379 50.1457 51.4166 45.6967 52.8043C44.7964 51.5181 43.9593 50.1712 43.2033 48.7637C44.6156 48.2047 45.989 47.5684 47.2979 46.7903C47.518 46.6832 47.6544 46.4727 47.6681 46.2393C47.6815 46.0118 47.5776 45.7978 47.4031 45.6615C47.2529 45.5388 47.1036 45.4234 46.9603 45.3127L46.9581 45.311C46.8153 45.2007 46.6784 45.0948 46.5424 44.984C46.3095 44.7719 46.0053 44.7891 45.8121 44.8812C36.8818 49.0036 27.1442 49.0047 18.1045 44.8805C17.9151 44.7908 17.6197 44.7731 17.3893 44.9718C17.2441 45.078 17.1002 45.1893 16.9623 45.2958L16.96 45.2977C16.8122 45.4118 16.6714 45.5206 16.5316 45.6223L16.4999 45.6453L16.4721 45.6731C16.3194 45.8258 16.2592 46.0308 16.2699 46.2127C16.2807 46.3971 16.3736 46.6318 16.6137 46.7594C17.9426 47.5112 19.2969 48.1711 20.7191 48.7383C19.9821 50.1484 19.1426 51.4978 18.2187 52.7864C13.8137 51.3988 9.35807 49.3233 4.72346 45.943C3.78136 35.6737 5.7575 25.3071 12.8592 14.6377C16.3098 13.0477 19.9863 11.8846 23.8402 11.1975C24.3057 12.0262 24.8005 13.0274 25.1367 13.8437L25.2859 14.2062L25.6736 14.1477C29.8668 13.5152 34.1312 13.5152 38.3244 14.1477L38.7079 14.2055L38.8593 13.8486C39.1963 13.0542 39.6912 12.0323 40.1366 11.1985ZM17.179 34.1066C17.179 37.4496 19.6459 40.26 22.719 40.26C25.8481 40.26 28.258 37.4472 28.259 34.1087C28.2865 30.7928 25.8742 27.9533 22.719 27.9533C19.5893 27.9533 17.179 30.7673 17.179 34.1066ZM35.7657 34.1066C35.7657 37.4496 38.2325 40.26 41.3057 40.26C44.463 40.26 46.8447 37.4453 46.8457 34.1087C46.8732 30.7928 44.4609 27.9533 41.3057 27.9533C38.176 27.9533 35.7657 30.7673 35.7657 34.1066Z" fill="white" fill-opacity="0.9" stroke="#C5A3BF" />
                                </svg>
                            </div>
                            </Link>
                            <Link to="https://t.me/playstrixnet"><div className={s.socialIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                    <path d="M18.0358 37.1535C19.6381 37.4206 20.9782 37.1535 23.5486 35.5894C26.4624 33.8153 35.2752 27.5133 40.7642 23.3691C42.9746 21.7 43.8282 22.7468 41.9564 24.5614L18.0358 37.1535ZM18.0358 37.1535C15.1125 36.6671 12.4014 35.9136 10.1886 34.9956L18.0358 37.1535ZM40.4629 22.9701C41.0419 22.5329 41.5635 22.2486 42.0028 22.1171C42.3974 21.999 42.9229 21.9524 43.2602 22.3216C43.6056 22.6996 43.4909 23.2183 43.3258 23.5817C43.1419 23.9864 42.7976 24.4423 42.3044 24.9204L42.3042 24.9205C41.6523 25.5519 40.9352 26.2407 40.1812 26.9647C38.1696 28.8966 35.8961 31.08 33.9008 33.097C32.5296 34.4831 31.2983 35.782 30.3797 36.8591C29.92 37.398 29.5447 37.8743 29.2708 38.2735C28.9883 38.6851 28.8449 38.9709 28.8022 39.1382C28.6971 39.5546 28.8186 40.077 29.1947 40.7038C29.566 41.3225 30.143 41.9707 30.8116 42.5984C32.1462 43.8512 33.7625 44.9463 34.5688 45.474L40.4629 22.9701ZM40.4629 22.9701C34.9731 27.1149 26.1785 33.4028 23.2886 35.1623C22.0211 35.9336 21.0903 36.3639 20.3001 36.5751C19.5235 36.7826 18.8611 36.7842 18.118 36.6603L18.1179 36.6603C15.2252 36.179 12.5519 35.4347 10.3802 34.5338L10.3802 34.5338C7.82591 33.4746 7.92135 29.9444 10.3803 28.9088L51.154 11.7409C51.154 11.7409 51.154 11.7409 51.154 11.7409C51.6609 11.5276 52.2156 11.454 52.7606 11.5279C53.3055 11.6017 53.8207 11.8202 54.2525 12.1608C54.6844 12.5013 55.017 12.9513 55.2159 13.464C55.4148 13.9767 55.4726 14.5334 55.3833 15.076L49.9755 47.878C49.505 50.7163 46.3974 52.3287 43.8093 50.923C41.3867 49.6069 37.7951 47.5829 34.569 45.4741L40.4629 22.9701Z" fill="white" fill-opacity="0.9" stroke="#C5A3BF" />
                                </svg>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className={s.bottomImageConnect}>
                        <div className={s.inBottomImage}>
                            <div className={s.grassImage}><img src={highGrassBottom} alt="" /></div>
                            <div className={s.rocksImage}><img src={rocksBottom} alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>




        </>

    )
}

export default Main