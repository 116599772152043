
import './App.css';
import Header from './Components/Header/Header';
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import Main from './Components/Main/Main';
import Bans from './Components/Bans/Bans';
import Shop from './Components/Shop/Shop';
import Gallary from './Components/Gallary/Gallary';
import Cart from './Components/Cart/Cart';
import { useEffect, useState } from 'react';
import Policy from './Components/Policy/Policy';
import Footer from './Components/Footer/Footer';

function App() {

  const [amountCart, setAmountCart] = useState(0)



  const getDataLocalStorage = () => {
    const cart = localStorage.getItem('cart'); 
  
   
    if (cart) {
      try {
        setAmountCart(JSON.parse(cart).length);  
      } catch (e) {
        console.error('Ошибка при парсинге данных из localStorage:', e);
        setAmountCart(0);  
      }
    } else {
      setAmountCart(0);  
    }
  };

  useEffect(() => {
    getDataLocalStorage()
  }, [])


  return (
    <BrowserRouter>
      <Header amountCart={amountCart} />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/bans" element={<Bans />} />
        <Route path="/shop" element={<Shop getDataLocalStorage={getDataLocalStorage} />} />
        <Route path="/gallery" element={<Gallary />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/cart" element={<Cart getDataLocalStorage={getDataLocalStorage} />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
