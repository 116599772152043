import Footer from '../Footer/Footer'
import s from './Policy.module.css'
const Policy = () => {
    return (
        <div className={s.policyArea}>
            <div className={s.inPolicyArea}>
                <div className={s.titlePolicy}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewpox="0 0 31 26" fill="none">
                        <path d="M30.6658 12.23L26.2726 1.91665C25.8272 0.870998 24.6011 0.406224 23.5744 0.893905L1.81543 11.2302C0.290842 11.9544 0.293594 14.1251 1.82001 14.8454L23.5785 25.1139C24.604 25.5978 25.8262 25.1333 26.2714 24.0904L30.6651 13.7991C30.8791 13.298 30.8793 12.7313 30.6658 12.23Z" fill="white" />
                    </svg>
                    <span>Политика конфиденциальности</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewpox="0 0 31 26" fill="none">
                        <path d="M0.334242 13.77L4.72737 24.0834C5.17278 25.129 6.39894 25.5938 7.42556 25.1061L29.1846 14.7698C30.7092 14.0456 30.7064 11.8749 29.18 11.1546L7.42151 0.886143C6.39605 0.402199 5.17378 0.866703 4.72855 1.90956L0.334882 12.2009C0.120948 12.702 0.120716 13.2687 0.334242 13.77Z" fill="white" />
                    </svg>
                </div>

                <div className={s.textPolicy}>

                    <p>	1.Общие положения</p>

                    1.1. Настоящее соглашение является официальным предложением "playstrix.net", в дальнейшем именуемым "Пожертвователь", предоставить пожертвования на поддержку сайта, далее по тексту - "Соглашение", и размещает публичное предложение на официальном интернет-сайте "https://playstrix.net/" (далее - "Интернет-сайт").
                    1.2. Моментом полного и безусловного принятия пожертвования Пожертвователем считается факт осуществления пожертвования на условиях настоящего Соглашения.

                    <p>2.	Понятия и определения</p>
                    2.1. В настоящей оферте, если контекст не требует иного, приведенные ниже термины имеют следующее значение:
                    "Пожертвование" - добровольные взносы в форме виртуальной валюты или материальной поддержки сайта;
                    "Интернет-сайт" - ресурс, на котором предоставляется возможность совершения пожертвований;
                    "Пожертвователь" - физическое или юридическое лицо, совершающее пожертвование;
                    "Получатель" - владелец сайта, получающий пожертвования;
                    "Пожертвовательный взнос" - добровольное предоставление Пожертвователем средств или ресурсов на поддержку сайта.

                    <p>3.	Предмет договора</p>

                    3.1. Пожертвователь совершает добровольное пожертвование в пользу Получателя на основании данного Соглашения.
                    Данное Соглашение регулирует предоставление пожертвований для поддержки сайта, включая:
                    добровольный выбор Пожертвователем суммы и способа пожертвования;
                    осуществление Пожертвователем пожертвования через Интернет-сайт;
                    получение и использование пожертвований Получателем на поддержку сайта.


                    <p>4.	Порядок пожертвования</p>

                    4.1 Пожертвователь имеет право осуществить пожертвование в любой форме и в любом размере, представленные на сайте Интернет-магазина.
                    4.2. Каждая позиция может быть представлена в пожертвовании в любом количестве.

                    <p>5. Права и обязанности сторон:</p>

                    5.1. Организация, принимающая пожертвования, имеет право:

                    в одностороннем порядке прекратить прием пожертвований в случае нарушения условий предоставления пожертвований.
                    5.2. Лицо, желающее сделать пожертвование, обязано:

                    своевременно сделать пожертвование в соответствии с условиями предоставления.
                    5.3. Лицо, совершающее пожертвование, имеет право:

                    сделать пожертвование через определенный канал;
                    требовать от организации исполнения условий предоставления пожертвований.
                    <p>6. Ответственность сторон</p>

                    6.1. Стороны несут ответственность за невыполнение или ненадлежащее выполнение условий предоставления пожертвований в соответствии с действующим законодательством.
                    6.2. Организация, принимающая пожертвования, не несет ответственности за:

                    достоверность предоставленной информации при совершении пожертвования;
                    задержки в обработке пожертвований, вызванные обстоятельствами, находящимися вне ее контроля;
                    незаконные действия, совершенные лицом при совершении пожертвования;
                    передачу своих личных данных третьим лицам.
                    6.3. Лицо, совершающее пожертвование, несет личную ответственность за вред, причиненный своими действиями другим людям или их имуществу, юридическим лицам, государству или моральным принципам нравственности, при совершении пожертвования.
                    6.4. В случае непредвиденных обстоятельств, не зависящих от сторон, стороны освобождаются от выполнения условий предоставления пожертвований.
                    6.5. Стороны стремятся к урегулированию любых разногласий путем переговоров.
                    <p>7. Другие условия</p>

                    7.1. Организация оставляет за собой право в одностороннем порядке вносить изменения в условия предоставления пожертвований при условии их предварительной публикации.
                    7.2. Организация создана для принятия пожертвований через определенные каналы.
                    7.3. Лицо, совершающее пожертвование, несет ответственность за достоверность предоставленной информации и предоставляет свое согласие на обработку своих данных в соответствии с законодательством о защите персональных данных.
                    7.4. Совершение пожертвования означает полное согласие с условиями предоставления пожертвований.
                    7.5. Фактическим моментом соглашения является дата принятия условий, в соответствии с законодательством об электронной коммерции.
                    7.6. Использование ресурсов для совершения пожертвований является бесплатным.
                    7.7. Информация, предоставленная при совершении пожертвования, является конфиденциальной и используется организацией исключительно для обработки пожертвований и связанных с ними процессов.

                    <p>8. Пожертвования и участие в программе</p>

                    8.1. Пожертвования передаются организации и не являются вашей собственностью. Организация может управлять, регулировать, контролировать, изменять или отменять их в любое время без предварительного уведомления вам. Организация не несет ответственности перед вами или какой-либо третьей стороной.
                    8.2. Лицо, желающее сделать пожертвование, вправе в одностороннем порядке отказаться от этого. При одностороннем отказе пожертвование не возвращается.
                    8.3. При успешном получении пожертвования организацией считается, что договор между сторонами был исполнен. В случае неисполнения организацией договора в течение 14 дней, лицо имеет право заменить пожертвование на товары или услуги в пределах суммы пожертвования.

                    <p>9. Срок действия договора</p>

                    9.1. Электронный договор считается заключенным с момента принятия предложения о совершении пожертвования.
                    9.2. Стороны имеют право расторгнуть договор в одностороннем порядке в случае невыполнения условий договора или в соответствии с законодательством.



                </div>
            </div>

        </div>


    )
}

export default Policy