import { useEffect, useState } from "react";
import s from './Gallary.module.css'
import layer1 from './images/bg.webp'
import table from './images/table.webp'
import rock from './images/rocks-bottom.webp'
import leaves from './images/forest-bg.webp'
import imagePrev from './images/prewimage.webp'
import Footer from "../Footer/Footer";

const ParallaxComponent = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getParallaxStyle = (speed) => ({
        transform: `translateY(${scrollY * speed}px)`,
        transition: '1s'
    });
    const getParallaxStyleNone = (speed) => ({
        transform: `translateY(${speed}px)`,
    });

    return (
        <div className={s.parallaxContainer}>
            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={layer1} alt="Layer 5" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyle(0.25)}>
                <img src={table} alt="Layer 5" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyle(0.14)}>
                <img src={leaves} alt="Layer 5" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={rock} alt="Layer 5" className={s.parallaxImage} />
            </div>

        </div>
    );
};

const SkeletonLoader = () => {
    return (
        <div className={s.imageGall} style={{
            backgroundColor: "#ddd",
            animation: "pulse 0.5s infinite ease-in-out"
        }} />
    );
};





const Gallary = () => {
    const [gallary, setGallery] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedStage, setSelectedStage] = useState(0)
    const [loading, setLoading] = useState(true)
    const checkGalleryPromo = async (hashtag) => {
        try {
            setLoading(true);
            if (hashtag === "locations") {
                setSelectedStage(0);
            } else if (hashtag === "events") {
                setSelectedStage(1);
            } else if (hashtag === "community") {
                setSelectedStage(2);
            } else if (hashtag === "arts") {
                setSelectedStage(3);
            }else {
                setSelectedStage(null); // Если не одно из этих значений
            }

            const response = await fetch(`/api/screenshots/${hashtag}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const result = await response.json();

            if (result.images && result.images.length > 0) {
                setGallery(result.images);
            } else {
                setGallery([]);
            }
        } catch (error) {
            console.error(error);
            setGallery([]);
        } finally {
            setLoading(false); // Завершаем загрузку
        }

    };

    useEffect(()=>{
        checkGalleryPromo('locations')
    },1000)
    





    return <>
        <ParallaxComponent />

        <div className={s.titleGalaryMain}>
            <div className={s.blockTextGallary}>
                <div className={s.tileGallary}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M30.6658 12.23L26.2726 1.91665C25.8272 0.870998 24.6011 0.406224 23.5744 0.893905L1.81543 11.2302C0.29084 11.9544 0.293596 14.1251 1.82001 14.8454L23.5785 25.1139C24.604 25.5978 25.8262 25.1333 26.2714 24.0904L30.6651 13.7991C30.8791 13.298 30.8793 12.7313 30.6658 12.23Z" fill="white" />
                    </svg>

                    <span>Галерея</span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M0.334242 13.77L4.72737 24.0834C5.17278 25.129 6.39894 25.5938 7.42556 25.1061L29.1846 14.7698C30.7092 14.0456 30.7064 11.8749 29.18 11.1546L7.42151 0.886145C6.39605 0.402199 5.17378 0.866703 4.72855 1.90956L0.334882 12.2009C0.120948 12.702 0.120716 13.2687 0.334242 13.77Z" fill="white" />
                    </svg>
                </div>

                <div className={s.descGallary}>
                    Тут мы собрали самые интересные места и самые яркие события проекта и нашего сообщества!
                </div>
            </div>
        </div>

        <div className={s.splitter}>

            <svg width="1920" height="10" viewBox="0 0 1920 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5L646 4.99994" stroke="#EA8345" stroke-width="2" stroke-linecap="round" />
                <path d="M684 5H1920" stroke="#EA8345" stroke-width="2" stroke-linecap="round" />
                <circle cx="665" cy="5" r="5" fill="#EA8345" />
            </svg>

            <div className={s.selectTypeGallary}>
                <div className={s.itemSelect + ' ' + (selectedStage == 0 ? s.selecteditemSelect : '')} onClick={() => { checkGalleryPromo('locations') }}>Локации</div>
                <div className={s.itemSelect + ' ' + (selectedStage == 1 ? s.selecteditemSelect : '')} onClick={() => { checkGalleryPromo('events') }}>События</div>
                <div className={s.itemSelect + ' ' + (selectedStage == 2 ? s.selecteditemSelect : '')} onClick={() => { checkGalleryPromo('community') }}>Сообщество</div>
                <div className={s.itemSelect + ' ' + (selectedStage == 3 ? s.selecteditemSelect : '')} onClick={() => { checkGalleryPromo('arts') }}>Арты</div>
            </div>

            <svg width="1920" height="10" viewBox="0 0 1920 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1919 5L1271 5" stroke="#EA8345" stroke-width="2" stroke-linecap="round" />
                <path d="M1233 5L-6.29425e-05 4.99989" stroke="#EA8345" stroke-width="2" stroke-linecap="round" />
                <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 1257 0)" fill="#EA8345" />
            </svg>
        </div>

        <div className={s.gallaryImages}>
            <div className={s.inGallaryImage}>


                {gallary.map((item, index) => {
                    return loading ? (
                        
                        <div className={s.itemsGallaryImage}>
                            <SkeletonLoader />
                        </div>
                    ) : (<div className={s.itemsGallaryImage}>
                        <div className={s.imageGall}><img src={item.url} alt="" onClick={() => setSelectedImage(item.url)} /></div>
                    </div>

                    )
                })}


                {selectedImage && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 99999,
                        }}
                        onClick={() => setSelectedImage(null)} // Закрытие модального окна
                    >
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{ maxWidth: "75%", maxHeight: "75%" }}
                        />
                    </div>
                )}


            </div>
        </div >

 
    </>
}

export default Gallary