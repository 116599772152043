import { useEffect, useRef, useState } from 'react';
import s from './Shop.module.css'
import layer1 from './images/strixes.webp'
import backImageCard from './images/cardBack.webp'

import popimageBack from './images/popimageBack.webp'
import { format } from 'date-fns';
const ParallaxComponent = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getParallaxStyle = (speed) => ({
        transform: `translateY(${scrollY * speed}px)`,
        transition: '1s'
    });
    const getParallaxStyleNone = (speed) => ({
        transform: `translateY(${speed}px)`,
    });

    return (
        <div className={s.parallaxContainer}>
            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={layer1} alt="Layer 5" className={s.parallaxImage} />
            </div>

        </div>
    );
};


const Shop = ({ getDataLocalStorage }) => {
    const notification = useRef('')
    const popup = useRef('undefined')


    const [currentDonatPopid, setCurrentPopDonid] = useState('');
    const [currentDonatPop, setCurrentPopDon] = useState('');
    const [currentpricePop, setCurrentpriceDon] = useState('');
    const [currentdescPop, setCurrentdescDon] = useState('');
    const [currentimgPop, setCurrentimgDon] = useState('');


    const [popupState, setPopUpState] = useState(false)
    const shopPop = (id, name, price, desc, img) => {
        setCurrentPopDonid(id)
        setCurrentPopDon(name)
        setCurrentpriceDon(price)
        setCurrentdescDon(desc)
        setCurrentimgDon(img)

        setPopUpState(prevState => !prevState);
    }


    useEffect(() => {
        // Проверяем, чтобы попап был доступен в DOM, и изменяем его стиль
        if (popup.current) {
            popup.current.style.display = popupState ? 'flex' : 'none';
        }
    }, [popupState]);


    const [donateitems, setDonateItems] = useState([]);
    const [lastDonate, setLastDonate] = useState([]);


    const getFullDonate = async () => {
        const response = await fetch('/api/alldonate').then((response) => response.json());
    
        setDonateItems(response.response)
    }



    const [exchangeRate, setExchangeRate] = useState(null);

    useEffect(() => {
        // Функция для получения курса обмена один раз при загрузке
        const fetchExchangeRate = async () => {
            try {
                const response = await fetch('https://www.cbr-xml-daily.ru/latest.js');
                const data = await response.json();
                setExchangeRate(data.rates['USD']);
            } catch (error) {
                console.error('Error fetching the data:', error);
            }
        };

        fetchExchangeRate();
    }, []);



    const getLastDonate = async () => {
        try {
            const responseData = await fetch('/api/lastdonate').then((response) => response.json());
            setLastDonate(responseData.response);
        } catch (error) {
            console.error('Error fetching the data:', error);
        }
    };


    const addToCart = (id, img, name, desc, amount, price) => {
        setNumber(1);

        const cart = JSON.parse(localStorage.getItem('cart')) || [];


        const existingProductIndex = cart.findIndex(item => item.name === name);

        if (existingProductIndex !== -1) {

            cart[existingProductIndex].amount += amount;
        } else {

            const dataCart = {
                id,
                amount,
                img,
                name,
                desc,
                price
            };



            cart.push(dataCart);
        }

        if (notification.current) { // Проверяем, что notification доступен
            notification.current.innerText = 'Добавлено в корзину';
            notification.current.style.display = 'block';
            
        }

        shopPop('', '', '', '', '')

        setTimeout(() => {
            if (notification.current) {
                notification.current.style.display = 'none';
            }
        }, 2000);
        localStorage.setItem('cart', JSON.stringify(cart));
        getDataLocalStorage()
    };

    useEffect(() => {
        getFullDonate();
        getLastDonate();
    }, [])




    const [number, setNumber] = useState(1);
    const handleChange = (event) => {
        if (event.target.value == 0 || event.target.value == '') {
            event.target.value = 1
        }
        setNumber(event.target.value); // обновляем значение
    };

    return <>
        <ParallaxComponent />

        <div className={s.areaShop}>
            <div className={s.inShop}>
                <div className={s.titleShop}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M30.6658 12.23L26.2726 1.91665C25.8272 0.870998 24.6011 0.406224 23.5744 0.893905L1.81543 11.2302C0.290842 11.9544 0.293596 14.1251 1.82002 14.8454L23.5785 25.1139C24.604 25.5978 25.8262 25.1333 26.2714 24.0904L30.6651 13.7991C30.8791 13.298 30.8793 12.7313 30.6658 12.23Z" fill="white" />
                    </svg>
                    <span>Товары</span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M0.334242 13.77L4.72737 24.0834C5.17278 25.129 6.39894 25.5938 7.42556 25.1061L29.1846 14.7698C30.7092 14.0456 30.7064 11.8749 29.18 11.1546L7.42151 0.886145C6.39605 0.402199 5.17378 0.866703 4.72855 1.90956L0.334882 12.2009C0.120948 12.702 0.120716 13.2687 0.334242 13.77Z" fill="white" />
                    </svg>
                </div>

                <div className={s.areaCardsProducts}>


                    {donateitems.map((item) => {

                        return (
                            <div className={s.itemProduct} key={item.id} onClick={() => { shopPop(item.id, item.name, item.price, item.description, item.image) }}>
                                <div className={s.imageItem}><img src={item.image} alt="" /></div>
                                <div className={s.areaNameProd}>
                                    <span className={s.nameProd}>{item.name}</span>
                                    <div className={s.prices}>
                                        <div className={s.priceMain}>{Math.ceil(item.price * exchangeRate).toFixed(0)}$</div>
                                        <div className={s.priceConverted + ' ' + s.simplePriceFont}>{'~'+item.price}₽</div>
                                    </div>
                                </div>
                                <div className={s.backImageCard}><img src={backImageCard} alt="" /></div>
                            </div>

                        )

                    })}




                </div>
            </div>
        </div>



        <div className={s.areaShop}>
            <div className={s.inShopBuy}>
                <div className={s.titleShop}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M30.6658 12.23L26.2726 1.91665C25.8272 0.870998 24.6011 0.406224 23.5744 0.893905L1.81543 11.2302C0.290842 11.9544 0.293596 14.1251 1.82002 14.8454L23.5785 25.1139C24.604 25.5978 25.8262 25.1333 26.2714 24.0904L30.6651 13.7991C30.8791 13.298 30.8793 12.7313 30.6658 12.23Z" fill="white" />
                    </svg>
                    <span>Последние покупки</span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M0.334242 13.77L4.72737 24.0834C5.17278 25.129 6.39894 25.5938 7.42556 25.1061L29.1846 14.7698C30.7092 14.0456 30.7064 11.8749 29.18 11.1546L7.42151 0.886145C6.39605 0.402199 5.17378 0.866703 4.72855 1.90956L0.334882 12.2009C0.120948 12.702 0.120716 13.2687 0.334242 13.77Z" fill="white" />
                    </svg>
                </div>

                <div className={s.smallText}>Приобретенные на сайте:</div>
                <div className={s.areaCardsOverflow}>


                    <div className={s.areaCardsProductsBuy}>
                        {lastDonate.map((lastDonItem,index) => {
                            const dateString = lastDonItem.created_at;
                            const date = (new Date(dateString.replace(" ", "T")));
                            const formattedDate = format(date,  'dd.MM.yyyy · HH:mm');
                         
                            return (
                                <div className={s.itemProduct} key={index} >
                                    <div className={s.dateBuy}>
                                        {formattedDate}
                                    </div>
                                    <div className={s.imageItem}><img src={lastDonItem.products[0].image} alt="" /></div>
                                    <div className={s.areaNameProd}>

                                        <div className={s.buyInfo}>
                                            <div className={s.nameBuyProd}>{lastDonItem.products[0].name+" x"+ lastDonItem.products[0].amount}</div>
                                            <div className={s.nameUser}>{lastDonItem.customer}</div>

                                        </div>
                                    </div>
                                    <div className={s.backImageCard}><img src={backImageCard} alt="" /></div>
                                </div>
                            )
                        })}



                    </div>
                </div>

            </div>
        </div>


        <div className={s.popupBuy} ref={popup} style={{ display: popupState ? 'flex' : 'none' }}>
            <div className={s.inPopup} style={{ background: `url(${popimageBack})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <div className={s.closePop} onClick={() => { shopPop('', '', '', '') }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M24 8L8 24" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 8L24 24" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div className={s.mainAreaPop}>
                    <div className={s.leftPartPop}>
                        <div className={s.nameProduct}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19" fill="none">
                                <path d="M23.6507 10.2894L20.4504 17.6245C20.0016 18.6533 18.7941 19.1113 17.7758 18.639L1.92486 11.2875C0.378044 10.5701 0.380798 8.37021 1.9294 7.65667L17.7799 0.353461C18.7969 -0.115171 20.0006 0.342568 20.4493 1.36862L23.65 8.68831C23.8732 9.19863 23.8734 9.77888 23.6507 10.2894Z" fill="white" />
                            </svg>
                            <span>{currentDonatPop}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19" fill="none">
                                <path d="M0.349331 10.2894L3.54956 17.6245C3.99843 18.6533 5.20589 19.1113 6.22417 18.639L22.0751 11.2875C23.622 10.5701 23.6192 8.37021 22.0706 7.65667L6.22014 0.353461C5.20305 -0.115171 3.99941 0.342568 3.55073 1.36862L0.349994 8.68831C0.126844 9.19863 0.126604 9.77888 0.349331 10.2894Z" fill="white" />
                            </svg>
                        </div>

                        <div className={s.descProductBuy}>
                            <pre>{currentdescPop}</pre>
                        </div>


                    </div>
                    <div className={s.rightPartPop} >
                        <img src={currentimgPop} alt="" />
                    </div>
                </div>
                <div className={s.bottomInputs}>
                    <div className={s.areaInputs}>
                        <label htmlFor="amountDonate">Введите количество <span>*</span></label>
                        <input type="number" id='amountDonate' placeholder='Количество' value={number} onChange={handleChange} required />
                    </div>

                    <div className={s.resultArea}>
                        <div className={s.textRes}>
                            <span className={s.textResTitle}>Итог:</span>
                            <div className={s.priceRes}>
                                <span className={s.mainMoney}>{Math.ceil(currentpricePop * exchangeRate).toFixed(0)}$</span>
                                <span className={s.convertMoney}>{'~'+currentpricePop}₽</span>

                            </div>
                        </div>
                        <div className={s.btnToCart} onClick={() => { addToCart(currentDonatPopid, currentimgPop, currentDonatPop, currentdescPop, number, currentpricePop) }}>
                            Добавить в корзину
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className={s.notification} ref={notification}>

        </div>

     
    </>
}
export default Shop