import { useEffect, useState } from 'react';
import s from './Bans.module.css'
import layer1 from './images/rocks-bg.webp'
import layer2 from './images/forest-bg.webp'
import layer3 from './images/leaves.webp'
import layer4 from './images/sign.webp'
import layer5 from './images/bgstrix.webp'
import Footer from '../Footer/Footer';
import leavesTop from './images/leaves_top.svg'
import bgfooter from './images/bgBan.webp'
import highGrassBottom from './images/highGrass-bottom.webp'
import rocksBottom from './images/rocks-bottom.webp'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const ParallaxComponent = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getParallaxStyle = (speed) => ({
        transform: `translateY(${scrollY * speed}px)`,
        transition: '1s'
    });
    const getParallaxStyleNone = (speed) => ({
        transform: `translateY(${speed}px)`,
    });

    return (
        <div className={s.parallaxContainer}>

            <div className={s.parallaxLayer} style={getParallaxStyle(0.25)}>
                <img src={layer5} alt="Layer 2" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyle(0.18)}>
                <img src={layer4} alt="Layer 2" className={s.parallaxImage} />
            </div>

            <div className={s.parallaxLayer} style={getParallaxStyle(0.12)}>
                <img src={layer3} alt="Layer 3" className={s.parallaxImage} />
            </div>

            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={layer2} alt="Layer 4" className={s.parallaxImage} />
            </div>
            <div className={s.parallaxLayer} style={getParallaxStyleNone(0)}>
                <img src={layer1} alt="Layer 5" className={s.parallaxImage} />
            </div>

        </div>
    );
};


const Bans = () => {

    const [punishment, setPunishment] = useState([]);
    const [currentPunishBan, setCurrentPunishBan] = useState('')
    const [currentPunishMute, setCurrentPunishMute] = useState('')
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const itemsPerPage = 15;
    const [totalItems, setTotalItems] = useState(0);
    const [currentPunishType, setCurrentPunishType] = useState('bans');




    const getMutes = async (page = 1) => {
        try {
            const response = await fetch(`/api/punishment/mutes?page=${page}&limit=${itemsPerPage}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();


            setPunishment(result.items);
            setTotalItems(result.total);
            setCurrentPage(page);
            setCurrentPunishMute('currentPunish');

        } catch (error) {
            console.error('Error fetching punishment data:', error);
        }
    };


    const getBans = async (page = 1) => {
        try {
            const response = await fetch(`/api/punishment/bans?page=${page}&limit=15`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const result = await response.json();

            setPunishment(result.items); // Устанавливаем текущие записи
            setTotalItems(result.total); // Устанавливаем общее количество записей
            setCurrentPunishBan('currentPunish');
        } catch (error) {
            console.error('Error fetching bans:', error);
        }
    };




    const handlePunishmentChange = (type) => {
        setCurrentPunishType(type);
        setCurrentPage(1); // Сброс на первую страницу при смене типа
        if (type === 'bans') {
            getBans(1);
        } else {
            getMutes(1);
        }
    };



    useEffect(() => {
        getMutes();
        getBans();

    }, [])

    const totalPages = Math.ceil(totalItems / itemsPerPage);



    return <>
        <div className={s.Bans}>
            <ParallaxComponent />
        </div>

        <div className={s.areaBans}>
            <div className={s.inareaBan}>
                <div className={s.titleBans}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M30.6658 12.23L26.2726 1.91665C25.8272 0.870998 24.6011 0.406224 23.5744 0.893905L1.81543 11.2302C0.290842 11.9544 0.293596 14.1251 1.82002 14.8454L23.5785 25.1139C24.604 25.5978 25.8262 25.1333 26.2714 24.0904L30.6651 13.7991C30.8791 13.298 30.8793 12.7313 30.6658 12.23Z" fill="white" />
                    </svg>
                    <span>Наказания</span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="none">
                        <path d="M0.334242 13.77L4.72737 24.0834C5.17278 25.129 6.39894 25.5938 7.42556 25.1061L29.1846 14.7698C30.7092 14.0456 30.7064 11.8749 29.18 11.1546L7.42151 0.886145C6.39605 0.402199 5.17378 0.866703 4.72855 1.90956L0.334882 12.2009C0.120948 12.702 0.120716 13.2687 0.334242 13.77Z" fill="white" />
                    </svg>
                </div>

                <div className={s.areaTablesinfo}>
                    <div className={s.btsSortTable}>
                        <div
                            className={`${s.itemSort} ${currentPunishType === 'bans' ? s.currentPunish : ''}`}
                            onClick={() => handlePunishmentChange('bans')}
                        >
                            Баны
                        </div>
                        <div
                            className={`${s.itemSort} ${currentPunishType === 'mutes' ? s.currentPunish : ''}`}
                            onClick={() => handlePunishmentChange('mutes')}
                        >
                            Муты
                        </div>
                    </div>

                    <div className={s.tableArea}>
                        <div className={s.headerTable}>
                            <div className={s.itemHT}>
                                Ник игрока
                            </div>
                            <div className={s.itemHT}>
                                Администратор
                            </div>
                            <div className={s.itemHT}>
                                Начало
                            </div>
                            <div className={s.itemHT}>
                                Окончание
                            </div>
                            <div className={s.itemHT}>
                                Причина
                            </div>
                        </div>


                        {punishment.map((item) => {
                            const startDate = format(new Date(+item.start), 'dd.MM.yyyy · HH:mm');
                            const endDate = format(new Date(+item.end), 'dd.MM.yyyy · HH:mm');

                            return (<div className={s.mainTable}>
                                <div className={s.tableItem}>{item.name}</div>
                                <div className={s.tableItem}>{item.operator}</div>
                                <div className={s.tableItem}>{startDate}</div>
                                <div className={s.tableItem}>{endDate == '01.01.1970 · 02:59' ? 'Навсегда' : endDate}</div>
                                <div className={s.tableItem}>{item.reason}</div>
                            </div>
                            )
                        })}


                        <div className={s.pagination}>
                            <div
                                className={`${s.prePag} ${currentPage === 1 ? s.disabled : ''}`}
                                onClick={() => {
                                    if (currentPage > 1) {
                                        const newPage = currentPage - 1;
                                        setCurrentPage(newPage);
                                        currentPunishType === 'bans' ? getBans(newPage) : getMutes(newPage);
                                    }
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.7071 3.29289C11.0976 3.68342 11.0976 4.31658 10.7071 4.70711L7.41421 8L10.7071 11.2929C11.0976 11.6834 11.0976 12.3166 10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289L9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>

                            <div className={s.numbersPag}>
                                {Array.from({ length: totalPages }, (_, index) => index + 1)
                                    .filter((page) => {

                                        if (totalPages <= 5) return true;
                                        if (currentPage <= 3) return page <= 5;
                                        if (currentPage >= totalPages - 2) return page >= totalPages - 4;
                                        return Math.abs(currentPage - page) <= 2;
                                    })
                                    .map((page) => (
                                        <div
                                            key={page}
                                            className={`${s.itemPag} ${currentPage === page ? s.activePag : ''}`}
                                            onClick={() => {
                                                setCurrentPage(page);
                                                currentPunishType === 'bans' ? getBans(page) : getMutes(page);
                                            }}
                                        >
                                            {page}
                                        </div>
                                    ))}
                            </div>


                            <div
                                className={`${s.nextPag} ${currentPage === totalPages ? s.disabled : ''}`}
                                onClick={() => {
                                    if (currentPage < totalPages) {
                                        const newPage = currentPage + 1;
                                        setCurrentPage(newPage);
                                        currentPunishType === 'bans' ? getBans(newPage) : getMutes(newPage);
                                    }
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289L10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071C4.90237 12.31658 4.90237 11.68342 5.29289 11.2929L8.58579 8L5.29289 4.70711C4.90237 4.31658 4.90237 3.68342 5.29289 3.29289Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>




        <div className={s.connectToPlaystrix}>
            <div className={s.inConnectToPLaystrix}>
                <div className={s.topImageConnect}><img src={leavesTop} alt="" /></div>
                <div className={s.mainAreaConnect}>
                    <div className={s.mainFooterImageBack}><img src={bgfooter} alt="" /></div>
                    <div className={s.textConnect}>Нужен разбан? Обращайся в Discord или Telegram!</div>
                    <div className={s.areaSocIcons}>
                        <Link to="https://discord.playstrix.net/"><div className={s.socialIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <path d="M40.1366 11.1985C44.0056 11.8886 47.7188 13.058 51.1523 14.6562C57.3377 23.8101 60.3936 34.0947 59.275 45.9427C54.6201 49.3379 50.1457 51.4166 45.6967 52.8043C44.7964 51.5181 43.9593 50.1712 43.2033 48.7637C44.6156 48.2047 45.989 47.5684 47.2979 46.7903C47.518 46.6832 47.6544 46.4727 47.6681 46.2393C47.6815 46.0118 47.5776 45.7978 47.4031 45.6615C47.2529 45.5388 47.1036 45.4234 46.9603 45.3127L46.9581 45.311C46.8153 45.2007 46.6784 45.0948 46.5424 44.984C46.3095 44.7719 46.0053 44.7891 45.8121 44.8812C36.8818 49.0036 27.1442 49.0047 18.1045 44.8805C17.9151 44.7908 17.6197 44.7731 17.3893 44.9718C17.2441 45.078 17.1002 45.1893 16.9623 45.2958L16.96 45.2977C16.8122 45.4118 16.6714 45.5206 16.5316 45.6223L16.4999 45.6453L16.4721 45.6731C16.3194 45.8258 16.2592 46.0308 16.2699 46.2127C16.2807 46.3971 16.3736 46.6318 16.6137 46.7594C17.9426 47.5112 19.2969 48.1711 20.7191 48.7383C19.9821 50.1484 19.1426 51.4978 18.2187 52.7864C13.8137 51.3988 9.35807 49.3233 4.72346 45.943C3.78136 35.6737 5.7575 25.3071 12.8592 14.6377C16.3098 13.0477 19.9863 11.8846 23.8402 11.1975C24.3057 12.0262 24.8005 13.0274 25.1367 13.8437L25.2859 14.2062L25.6736 14.1477C29.8668 13.5152 34.1312 13.5152 38.3244 14.1477L38.7079 14.2055L38.8593 13.8486C39.1963 13.0542 39.6912 12.0323 40.1366 11.1985ZM17.179 34.1066C17.179 37.4496 19.6459 40.26 22.719 40.26C25.8481 40.26 28.258 37.4472 28.259 34.1087C28.2865 30.7928 25.8742 27.9533 22.719 27.9533C19.5893 27.9533 17.179 30.7673 17.179 34.1066ZM35.7657 34.1066C35.7657 37.4496 38.2325 40.26 41.3057 40.26C44.463 40.26 46.8447 37.4453 46.8457 34.1087C46.8732 30.7928 44.4609 27.9533 41.3057 27.9533C38.176 27.9533 35.7657 30.7673 35.7657 34.1066Z" fill="white" fill-opacity="0.9" stroke="#C5A3BF" />
                            </svg>
                        </div>
                        </Link>
                        <Link to="https://t.me/playstrixnet"><div className={s.socialIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <path d="M18.0358 37.1535C19.6381 37.4206 20.9782 37.1535 23.5486 35.5894C26.4624 33.8153 35.2752 27.5133 40.7642 23.3691C42.9746 21.7 43.8282 22.7468 41.9564 24.5614L18.0358 37.1535ZM18.0358 37.1535C15.1125 36.6671 12.4014 35.9136 10.1886 34.9956L18.0358 37.1535ZM40.4629 22.9701C41.0419 22.5329 41.5635 22.2486 42.0028 22.1171C42.3974 21.999 42.9229 21.9524 43.2602 22.3216C43.6056 22.6996 43.4909 23.2183 43.3258 23.5817C43.1419 23.9864 42.7976 24.4423 42.3044 24.9204L42.3042 24.9205C41.6523 25.5519 40.9352 26.2407 40.1812 26.9647C38.1696 28.8966 35.8961 31.08 33.9008 33.097C32.5296 34.4831 31.2983 35.782 30.3797 36.8591C29.92 37.398 29.5447 37.8743 29.2708 38.2735C28.9883 38.6851 28.8449 38.9709 28.8022 39.1382C28.6971 39.5546 28.8186 40.077 29.1947 40.7038C29.566 41.3225 30.143 41.9707 30.8116 42.5984C32.1462 43.8512 33.7625 44.9463 34.5688 45.474L40.4629 22.9701ZM40.4629 22.9701C34.9731 27.1149 26.1785 33.4028 23.2886 35.1623C22.0211 35.9336 21.0903 36.3639 20.3001 36.5751C19.5235 36.7826 18.8611 36.7842 18.118 36.6603L18.1179 36.6603C15.2252 36.179 12.5519 35.4347 10.3802 34.5338L10.3802 34.5338C7.82591 33.4746 7.92135 29.9444 10.3803 28.9088L51.154 11.7409C51.154 11.7409 51.154 11.7409 51.154 11.7409C51.6609 11.5276 52.2156 11.454 52.7606 11.5279C53.3055 11.6017 53.8207 11.8202 54.2525 12.1608C54.6844 12.5013 55.017 12.9513 55.2159 13.464C55.4148 13.9767 55.4726 14.5334 55.3833 15.076L49.9755 47.878C49.505 50.7163 46.3974 52.3287 43.8093 50.923C41.3867 49.6069 37.7951 47.5829 34.569 45.4741L40.4629 22.9701Z" fill="white" fill-opacity="0.9" stroke="#C5A3BF" />
                            </svg>
                        </div>
                        </Link>
                    </div>

                </div>
                <div className={s.bottomImageConnect}>
                    <div className={s.inBottomImage}>
                        <div className={s.grassImage}><img src={highGrassBottom} alt="" /></div>
                        <div className={s.rocksImage}><img src={rocksBottom} alt="" /></div>
                    </div>
                </div>
            </div>
        </div>




    </>
}

export default Bans;